<template>
  <div class="">
    <div id="map1" ref="map1" class="w-100 min-90vh position-absolute"></div>
    <div class="search-truck d-flex align-items-center min-90vh">
      <b-container>
        <b-row>
          <b-col cols="11" md="9" lg="5">z
            <div class="trucks-box mt-5 mb-5 d-flex flex-column">
              <div v-if="trucksLoading" class="truck-box-loader pt-5 p-4 mt-auto d-flex flex-column justify-content-center align-items-center text-center">
                <div class="lds-spinner">
                  <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>
                <h4 class="truch-box-search">{{ $t('transportation.lookingDrivers') }}</h4>
                <p class="truck-box-search-note m-0">{{ $t('transportation.notifyDriver') }}</p>
              </div>
              <ul v-else-if="trucks.length > 0 && !trucksLoading" class="list-unstyled truck-boxes p-4">
                <li v-for="(item, index) in trucks" :key="index" class="d-flex gap_1 flex-wrap align-items-center justify-content-between truck-box-card p-3 mb-4">
                  <!-- main info  -->
                  <div  v-if="item && item.type == 'driver'" class="d-flex align-items-center truck-box-main-card gap_1">
                    <div class="truck-box-user-container">
<!--                      <img :src="item.driver.image" alt="" class="truck-box-user" />-->
                      <div class="profile-img-driver"       :style="{
                    'background-image':
                      'url(' + item.driver.image + ')',
                  }"></div>
                      <span class="d-flex align-items-center justify-content-center rates mt-2">{{item.rates}}<i class="las la-star icon"></i></span>
                    </div>
                    <div>
                      <div class="truck-box-footer-item mb-3">
                        <h5 class="truck-box-footer-title">{{ $t('transportation.driverName') }}</h5>
                        <h4 class="truck-box-footer-response">{{item.driver.name}}</h4>
                      </div>
                      <div class="truck-box-footer-item mb-3">
                        <h5 class="truck-box-footer-title">{{ $t('transportation.vehicleType') }}</h5>
                      </div>
                    </div>
                  </div>
                  <div  v-if="item && item.type == 'company'" class="d-flex align-items-center truck-box-main-card gap_1">
                    <div class="truck-box-user-container">
<!--                      <img :src="item.driver.image" alt="" class="truck-box-user" />-->
                      <div class="profile-img-driver"       :style="{
                    'background-image':
                      'url(' + item.company.image + ')',
                  }"></div>
                      <span class="d-flex align-items-center justify-content-center rates mt-2">{{item.rates}}<i class="las la-star icon"></i></span>
                    </div>
                    <div>
                      <div class="truck-box-footer-item mb-3">
                        <h5 class="truck-box-footer-title">{{ $t('transportation.companyName') }}</h5>
                        <h4 class="truck-box-footer-response">{{item.company.name}}</h4>
                      </div>
                      <div class="truck-box-footer-item mb-3">
                        <h5 class="truck-box-footer-title">{{ $t('transportation.vehicleType') }}</h5>
                      </div>
                    </div>
                  </div>
                  <div class="truck-box-other d-flex flex-column justify-content-center align-items-center">
                    <div class="truck-box-footer-item mb-3 text-center">
                      <h5 class="truck-box-footer-title" v-if="item.type === 'driver'">{{ $t('transportation.driverOffer') }}</h5>
                      <h5 class="truck-box-footer-title" v-else>{{ $t('transportation.companyOffer') }}</h5>
                      <h4 class="truck-box-footer-response price">{{item.price}}</h4>
                    </div>
                    <div class="d-flex gap_1">
                      <b-button variant="primary" class="truck-box-btn-primary" @click="updateOfferStatus(item.offer_id, item.tripRequestId, 'accepted')">{{ $t('transportation.accept') }}</b-button>
                      <b-button variant="danger" class="truck-box-btn-danger" @click="updateOfferStatus(item.offer_id, item.tripRequestId,'rejected')">{{ $t('transportation.refuse') }}</b-button>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="truc-box-footer-container mt-auto">
                <b-button v-b-modal.modal-cancel-journy class="cancel-btn border-0 d-block m-auto">{{ $t('transportation.refuseTrip') }}</b-button>
                <div class="truck-box-footer d-flex align-items-end justify-content-between border mt-4 p-4">
                  <div>
                    <div class="truck-box-footer-item mb-3">
                      <h5 class="truck-box-footer-title">{{ $t('transportation.startLocation') }}</h5>
                      <h4 class="truck-box-footer-response">{{ allInfoData.startAddress }}</h4>
                    </div>
                    <div class="truck-box-footer-item ">
                      <h5 class="truck-box-footer-title">{{ $t('transportation.endLocation') }}</h5>
                      <h4 class="truck-box-footer-response">{{ allInfoData.endAddress }}</h4>
                    </div>
                  </div>
                  <div class="truck-box-footer-item">
                    <h5 class="truck-box-footer-title">{{ $t('transportation.approximationPrice') }}</h5>
                    <h4 class="truck-box-footer-response price">{{ allInfoData.price }} {{ $t('endUser.rs') }}</h4>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div class="search-truck-user">
        <div class="lds-ripple d-flex align-items-center justify-content-center loading">
          <div></div>
          <div></div>
          <div></div>
          <img src="@/assets/images/ibbil/truck-user1.jpg" alt="" class="img-flui" />
        </div>
      </div>
      <b-modal id="modal-cancel-journy"  hide-footer hide-header centered class="border-0 cancel-journy-modal">
        <form>
          <div class="form-group mb-5">
            <label for="cancel-reason" class="d-block label">{{ $t('transportation.cancelReason')}}<span>*</span></label>
            <textarea id="cancel-reason" :placeholder="$t('transportation.cancelReason')" v-model="reason" class="msg d-block w-100"></textarea>
          </div>
          <b-button @click="sendIssue()" class="border-0 submit-cancel-btn d-block m-auto">{{ $t('transportation.send') }}</b-button>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import transportationServices from '../services/transportation'
export default {
  props: ['allInfoData', 'requestTrip'],
  data () {
    return {
      reason: '',
      trucksLoading: false,
      trucks: [
        {
          driver: {
            image: 'asdasd',
            name: 'asdasd',
            rate: 3.5
          },
          vehicle: {
            plateNumber: 123,
            model: '123',
            year: 21312
          },
          price: 123123
        }
      ]
    }
  },
  methods: {
    drawMap () {
      // configuration map
      const styleMap = [
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e9e9e9'
            },
            {
              lightness: 17
            }
          ]
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5'
            },
            {
              lightness: 20
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff'
            },
            {
              lightness: 17
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#ffffff'
            },
            {
              lightness: 29
            },
            {
              weight: 0.2
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff'
            },
            {
              lightness: 18
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff'
            },
            {
              lightness: 16
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5'
            },
            {
              lightness: 21
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dedede'
            },
            {
              lightness: 21
            }
          ]
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              visibility: 'on'
            },
            {
              color: '#ffffff'
            },
            {
              lightness: 16
            }
          ]
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              saturation: 36
            },
            {
              color: '#333333'
            },
            {
              lightness: 40
            }
          ]
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f2f2f2'
            },
            {
              lightness: 19
            }
          ]
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#fefefe'
            },
            {
              lightness: 20
            }
          ]
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#fefefe'
            },
            {
              lightness: 17
            },
            {
              weight: 1.2
            }
          ]
        }
      ]
      const location = { lat: 30.064742, lng: 31.249509 }
      const mapZoom = 12
      const dropLocation = { lat: 29.9925934, lng: 31.1432647 }
      console.log(dropLocation)
      // configuration map
      this.map = new window.google.maps.Map(this.$refs.map1, {
        zoom: mapZoom,
        center: location,
        styles: styleMap
      })
      // const priceTag = document.createElement('div')
      // priceTag.append(`      <div class="search-truck-user">
      //   <div class="lds-ripple d-flex align-items-center justify-content-center loading">
      //     <div></div>
      //     <div></div>
      //     <div></div>
      //     <img src="@/assets/images/ibbil/truck-user1.jpg" alt="" class="img-flui" />
      //   </div>
      // </div>`)
      // const myMap = this.map
      // const markerView = new window.google.maps.marker.AdvancedMarkerView({
      //   myMap,
      //   position: dropLocation,
      //   content: priceTag
      // })
      // // const marker = new window.google.maps.Marker({
      // //   position: dropLocation,
      // //   map: this.map
      // // })
      // // 0116378862 // :TODO password gemy
      // console.log(markerView)
      // markerView.setMap(this.map)
    },
    onMessage () {
      this.trucksLoading = true
      try {
        this.$messaging.onMessage((payload) => {
          this.trucksLoading = false
          // if (JSON.parse(payload.notification.body).data.driver) {
          //   const ifFindDriver = this.trucks.findIndex(offer => offer.driver.id === JSON.parse(payload.notification.body).data.driver.id)
          //   if (ifFindDriver > -1) {
          //     this.trucks.splice(ifFindDriver, 1)
          //   }
          // }
          this.trucks.unshift(JSON.parse(payload.notification.body).data)
        })
      } catch (e) {
        console.error('Error : ', e)
        this.trucksLoading = false
      }
    },
    updateOfferStatus (offerId, requestId, status) {
      transportationServices.updateOfferStatus(offerId, requestId, status, this.allInfoData.distance.value).then(res => {
        if (status === 'accepted') {
          this.$emit('finishRequests', { status: 'success', data: res.data })
        } else {
          const ifFindDriver = this.trucks.findIndex(offer => offer.offer_id === offerId)
          if (ifFindDriver > -1) {
            this.trucks.splice(ifFindDriver, 1)
            if (this.trucks.length === 0) {
              this.trucksLoading = true
            }
          }
        }
      })
    },
    sendIssue () {
      transportationServices.cancelTrip(this.requestTrip, this.reason).then(res => {
        this.$router.push({ name: 'transportation' })
        core.showSnackbar('success', res.data.message)
      })
    }
  },
  mounted () {
    core.index()
    this.drawMap()
    this.onMessage()
  },
  created () {
    this.trucks = []
  }
}
</script>
<style lang="scss">
.profile-img-driver {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1px solid #707070;
  background-size: contain;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
}
.min-90vh{
 min-height: 90vh;
}
  .search-truck {
    // min-height: calc(100vh - 105px);
    .trucks-box {
      background-color: #fff;
      border-radius: 24px;
      box-shadow: 0px 3px 20px #00000029;
      height: 600px;
      overflow: hidden auto;
      position: relative;
      z-index: 999;
      .truch-box-search {
        color: #646464;
        font-weight: 600 !important;
        margin-bottom: 11px;
        font-size: 25px;
      }
      .truck-box-search-note {
        color: #646464;
        font-size: 20px;
      }
      .cancel-btn {
        min-width: 151px;
        height: 40px;
        background-color: #E8E8E8;
        border-radius: 5px;
        font-size: 20px;
        color: #646464;
      }
      .truck-box-footer {
        border-radius: inherit;
        border-color: #707070;
        .truck-box-footer-title {
          margin-bottom: 6px;
          color: #B1B1B1;
          font-size: 16px;
        }
        .truck-box-footer-response {
          color: #9B9B9B;
          font-size: 20px;
          font-weight: 600;
          &.price {
            color: #646464;
            font-size: 25px;
          }
        }
      }
      .truck-box-card {
        border-radius: 7px;
        box-shadow: 0px 3px 20px #0000001F;
        background-color: #fff;
        // overflow: hidden auto;
        // max-width: ;
        .truck-box-user-container {
          .truck-box-user {
            border: 1px solid #707070;
            max-width: 90px;
            border-radius: 50%;
          }
          .rates {
            font-size: 24px;
            color: #8D8D8D;
          }
          .icon {
            color: #FFA100;
            font-size: 30px;
            margin-inline-start: 4px;
          }
        }
        .truck-box-footer-title {
          margin-bottom: 6px;
          color: #B1B1B1;
          font-size: 16px;
        }
        .truck-box-footer-response {
          color: #9B9B9B;
          font-size: 20px;
          font-weight: 600;
          &.price {
            color: #646464;
            font-size: 25px;
          }
        }
        .truck-box-btn-danger, .truck-box-btn-primary {
          min-width: 85px;
          height: 40px;
          border-radius: 4px;
          border: none;
          font-size: 20px;
        }
        .truck-box-btn-danger {
          background-color: #EC5D61;
        }
        .truck-box-btn-primary {
          background: #6EBF97;
        }
      }
    }
    .search-truck-user {
      position: absolute;
      bottom: 100px;
      left: 40px;
      img {
        max-width: 60px;
        border-radius: 50%;
      }
    }
    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 180px;
        height: 180px;
        div {
          border: 1px solid #64646480;
          opacity: 1;
          border-radius: 50%;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: 1s;
        }
        &.loading div {
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        }
         div:nth-child(2) {
          animation-delay: .8s;
          width: 140px;
          height: 140px;
        }
        div:nth-child(3) {
          animation-delay: 1.6s;
          width: 100px;
          height: 100px;
        }
        @keyframes lds-ripple {
          0% {
            opacity: 0;
          }
          4.9% {
            opacity: 0;
          }
          5% {
            border-color: var(--iq-cyan);
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
    }
    .lds-spinner {
      display: inline-block;
      position: relative;
      width: 100px;
      height: 100px;
      div {
        transform-origin: 40px 40px;
        animation: lds-spinner 1.2s linear infinite;
      }
      div:after {
        content: " ";
        display: block;
        position: absolute;
        top: -2px;
        left: 37px;
        width: 10px;
        height: 20px;
        border-radius: 80%;
        background: var(--iq-cyan);
      }
      div:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -1.1s;
      }
      div:nth-child(2) {
        transform: rotate(30deg);
        animation-delay: -1s;
      }
      div:nth-child(3) {
        transform: rotate(60deg);
        animation-delay: -0.9s;
      }
      div:nth-child(4) {
        transform: rotate(90deg);
        animation-delay: -0.8s;
      }
      div:nth-child(5) {
        transform: rotate(120deg);
        animation-delay: -0.7s;
      }
      div:nth-child(6) {
        transform: rotate(150deg);
        animation-delay: -0.6s;
      }
      div:nth-child(7) {
        transform: rotate(180deg);
        animation-delay: -0.5s;
      }
      div:nth-child(8) {
        transform: rotate(210deg);
        animation-delay: -0.4s;
      }
      div:nth-child(9) {
        transform: rotate(240deg);
        animation-delay: -0.3s;
      }
      div:nth-child(10) {
        transform: rotate(270deg);
        animation-delay: -0.2s;
      }
      div:nth-child(11) {
        transform: rotate(300deg);
        animation-delay: -0.1s;
      }
      div:nth-child(12) {
        transform: rotate(330deg);
        animation-delay: 0s;
      }
      @keyframes lds-spinner {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }
  #modal-cancel-journy {
    .modal-content {
      border: none;
      border-radius: 24px;
      box-shadow: 0px 3px 20px #00000029;
    }
    .modal-body {
      padding: 40px;
      .label {
        color: #494845;
        font-weight: 600;
        font-size: 25px;
        span {
          color: #EC5D61;
        }
      }
      .msg {
        border-radius: 7px;
        height: 156px;
        resize: none;
        border: 1px solid #DEDEDE;
        padding: 18px 24px;
        // color: #B9B9B9;
        &:focus {
          box-shadow: none;
          outline: 0;
        }
      }
      .submit-cancel-btn {
        background-color: #E8E8E8;
        border-radius: 5px;
        min-width: 151px;
        height: 40px;
        font-size: 20px;
        color: #646464;
      }
    }
  }
</style>
