<template>
  <div>
  <b-row>
    <truck-box v-for="(item, index) in trucks" :numberOfKilos="allInfoData.distance.value" :key="index" :item="item" @click.native="handleSelected(item)" :selected="selectedTruck"/>
  </b-row>
  <div class="d-flex justify-content-center mb-5">
    <b-button variant="primary" @click="changeComponent" class="px-5 py-2 iq-border-radius-5 text-center font-weight-bold">
      <span class="mx-2"> {{ $t('transportation.continue') }}</span>
      <i class="las la-angle-left"></i>
    </b-button>
  </div>
</div>
</template>

<script>
import TruckBox from './truckBox.vue'
import { core } from '@/config/pluginInit'
import transportationServices from '@/modules/servicePages/transportation/services/transportation'
export default {
  props: ['allInfoData'],
  data () {
    return {
      selectedTruck: '',
      trucks: [],
      price: ''
      // trucks: [
      //   {
      //     img: require('@/assets/images/ibbil/transportation/truck-icon.svg'),
      //     title: 'دينا 3 متون',
      //     features: ['مدمجة مع ونش', 'نكييف', 'مفتوحة', 'تكييف', 'مفتوحة', 'مدمجة مع ونش', 'مدمجة مع ونش', 'تكييف', 'مفتوحة'],
      //     qty: 'من 1 الى 3 متون',
      //     price: '324 ر.س',
      //     id: 1
      //   },
      //   {
      //     img: require('@/assets/images/ibbil/transportation/truck-icon.svg'),
      //     title: 'دينا 3 متون',
      //     features: ['مدمجة مع ونش', 'نكييف', 'مفتوحة', 'تكييف', 'مفتوحة', 'مدمجة مع ونش', 'مدمجة مع ونش', 'تكييف', 'مفتوحة'],
      //     qty: 'من 1 الى 3 متون',
      //     price: '324 ر.س',
      //     id: 2
      //   },
      //   {
      //     img: require('@/assets/images/ibbil/transportation/truck-icon.svg'),
      //     title: 'دينا 3 متون',
      //     features: ['مدمجة مع ونش', 'نكييف', 'مفتوحة', 'تكييف', 'مفتوحة', 'مدمجة مع ونش', 'مدمجة مع ونش', 'تكييف', 'مفتوحة'],
      //     qty: 'من 1 الى 3 متون',
      //     price: '324 ر.س',
      //     value: 3
      //   },
      //   {
      //     img: require('@/assets/images/ibbil/transportation/truck-icon.svg'),
      //     title: 'دينا 3 متون',
      //     features: ['مدمجة مع ونش', 'نكييف', 'مفتوحة', 'تكييف', 'مفتوحة', 'مدمجة مع ونش', 'مدمجة مع ونش', 'تكييف', 'مفتوحة'],
      //     qty: 'من 1 الى 3 متون',
      //     price: '324 ر.س',
      //     id: 4
      //   },
      //   {
      //     img: require('@/assets/images/ibbil/transportation/truck-icon.svg'),
      //     title: 'دينا 3 متون',
      //     features: ['مدمجة مع ونش', 'نكييف', 'مفتوحة', 'تكييف', 'مفتوحة', 'مدمجة مع ونش', 'مدمجة مع ونش', 'تكييف', 'مفتوحة'],
      //     qty: 'من 1 الى 3 متون',
      //     price: '324 ر.س',
      //     id: 5
      //   },
      //   {
      //     img: require('@/assets/images/ibbil/transportation/truck-icon.svg'),
      //     title: 'دينا 3 متون',
      //     features: ['مدمجة مع ونش', 'نكييف', 'مفتوحة', 'تكييف', 'مفتوحة', 'مدمجة مع ونش', 'مدمجة مع ونش', 'تكييف', 'مفتوحة'],
      //     qty: 'من 1 الى 3 متون',
      //     price: '324 ر.س',
      //     id: 6
      //   }
      // ]
    }
  },
  methods: {
    handleSelected (truck) {
      console.log(truck)
      if (truck.id === this.selectedTruck) {
        this.selectedTruck = ''
        this.this.price = ''
      } else {
        this.selectedTruck = truck.id
        this.price = (truck.kilometerPrice * Number(this.allInfoData.distance.value) / 1000).toFixed(2)
      }
      // this.$emit('selectedTruck', { truckId: truck })
    },
    changeComponent () {
      if (this.selectedTruck) {
        this.$emit('changeForm', { vehicleTypeId: this.selectedTruck, price: this.price }, true)
      } else {
        core.showSnackbar('error', this.$t('transportation.selectTruck'))
      }
    },
    getAllCategoryCar () {
      transportationServices.getAllCategoryCar().then(res => {
        this.trucks = res.data.data
      })
    }
  },
  components: { TruckBox },
  created () {
    this.getAllCategoryCar()
  }
}
</script>
