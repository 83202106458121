<template>
  <b-col sm="6" md="4" lg="3">
    <div class="truck-box mb-4"  :class="[selected ===item.id?'selected':'']">
      <div class="truck-box-img-wrapper">
        <img :src="item.image" alt="" class="img-fluid truck-box-img" />
      </div>
      <h3 class="truck-box-title text-center"> {{ item.name }} {{ $t('transportation.camel') }} </h3>
      <div class="d-flex align-items-center justify-content-center flex-wrap gap_1">
        <h5 class="truck-box-price-title mb-0">{{ $t('transportation.approximatePrice') }}</h5>
        <h4 class="truck-box-price mb-0"> {{ (item.kilometerPrice * (Number(numberOfKilos) /1000)).toFixed(2)  }}</h4>
      </div>
    </div>
  </b-col>
</template>

<script>
export default {
  props: ['item', 'selected', 'numberOfKilos'],
  data () {
    return {
      featuresIsOpened: false,
      featuresHeight: '80px'
    }
  },
  methods: {
    // showMore (e) {
    //   e.stopPropagation()
    //   this.featuresIsOpened = !this.featuresIsOpened
    //   if (this.featuresIsOpened) {
    //     this.featuresHeight = '300px'
    //   } else {
    //     this.featuresHeight = '80px'
    //   }
    // },
  }

}
</script>

<style lang="scss">
  .truck-box {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
    border: 4px solid transparent;
    transition: .5s;
    &:hover, &.selected {
      border-color: #6EBF97;
    }
    .truck-box-img-wrapper {
      position: relative;
      margin-bottom: 12px;
      padding-bottom: 12px;
      &::after {
        content: "";
        width: calc(100% + 42px);
        border: 1px solid rgba(0, 0, 0, .02);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      .truck-box-img {
        max-width: 160px !important;
        margin: 0 auto 18px;
        min-width: 140px;
        display: block;
      }
    }
    .truck-box-title {
      font-weight: bold !important;
      margin-bottom: 20px !important;
      font-size: 25px !important;
      color: #646464 !important;
    }
    .truck-box-price-title {
      color: #646464;
      font-size: 16px;
    }
    .truck-box-price {
      font-weight: bold !important;
      font-size: 25px !important;
      color: #646464 !important;
    }
    .truck-box-feat {
      gap: 8px;
      height: auto;
      transition: .5s;
      overflow: hidden;
      .truck-box-feat-item {
        font-size: 14px;
        color: #646464;
        border: .5px solid #7C7C7C93;
        border-radius: 5px;
        padding: 5px 8px;
        box-shadow: 0px 0px 3px #00000019;
        min-width: 70px;
      }
      .truck-box-feat-bg {
        left: 0;
        bottom: 0;
        right: 0;
        height: 45px;
        background: linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%);
        transition: 2s;
      }
    }
    .show-more-btn {
      font-size: 14px;
      color: #6EBF97;
      font-weight: 600;
      .icon {
        margin-inline-end: 8px;
      }
    }
    .v-enter-active,
    .v-leave-active {
      transition: opacity 2s ease;
    }

    .v-enter-from,
    .v-leave-to {
      opacity: 0;
    }
  }
</style>
