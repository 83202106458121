import { marketPlace, insurance, website, transportation, singleSignOn } from '@/axios'

export default {
  getAllNotifications () {
    return website().get('/user/notifications')
  },
  getAllOrders (page) {
    return marketPlace().get(`customer/orders?page=${page}`)
  },
  getOrderInvoice (orderId) {
    return marketPlace().patch('customer/order/invoice', { order_id: +orderId })
  },
  getUserOrder (id, company, status, page) {
    return insurance().get(`insurances/user?id=${id}&company=${company}&status=${status}&page=${page}`)
  },
  getInsuranceCompany () {
    return insurance().get('insuranceServiceProvider/available')
  },
  getInsuranceInvoice (id) {
    return insurance().get(`insurances/invoice/${id}`)
  },
  getUserTripsOrder (status, page = 1) {
    return transportation().get(`trips/user?filters[status]=${status}&page=${page}`)
  },
  getTripsStatus () {
    return transportation().get('trips/status')
  },
  getTripsCount () {
    return transportation().get('trips/user/count')
  },
  addComment (payload) {
    return transportation().post('v1/user/reviews', payload)
  },
  // wallet
  getWallaetDetails () {
    return website().get('user/wallets')
  },
  editWalletInfo (payload) {
    return website().patch('user/wallets', payload)
  },
  editPinCode (payload) {
    return website().patch('user/wallets/change-pin', payload)
  },
  forgetPinCode (payload) {
    return website().post('user/wallets/forget-pin', payload)
  },
  createPinCode (payload) {
    return website().post('user/wallets/reset-pin', payload)
  },
  requestWithdraw (payload) {
    return website().post('user/wallets/withdrawal', payload)
  },
  deposit (payload) {
    return website().post('user/wallets/deposit', payload)
  },
  initiate (payload) {
    return website().post('payment/initiate', payload)
  },
  checkPaymentStatus (payload) {
    return website().post('payment/check-status', payload)
  },
  getAllTransactions (page) {
    return website().get(`user/wallets/transactions?page=${page}&status=completed`)
  },
  successDeposit (data) {
    return website().post('user/wallets/success-deposit', data)
  },
  // getUserInfo () {
  //   return singleSignOn().get('v1/profile/users')
  // },
  getUserData () {
    return singleSignOn().get('v1/profile/users')
  },
  editUserProfile (payload) {
    return singleSignOn().put('v1/profile/users', payload)
  },
  uploadProfile (payload) {
    return website().post('upload', payload)
  },
  changePassword (payload) {
    return singleSignOn().patch('v1/profile/users/change-password', payload)
  },
  createPassword (payload) {
    return singleSignOn().post('sso-reset-password', payload)
  }
}
