<template>
  <div>
    <div class="fees-company-container">
      <b-alert variant="success" show class="d-flex flex-column flex-md-row align-items-center iq-border-radius-10 mb-5">
        <div class="d-flex justify-content-center alert-info-head flex-column align-items-center pl-md-5 pb-4 pb-md-0 position-relative mb-4 mb-md-0">
          <h2 class="text-cyan font-weight-bold text-nowrap">{{ $t('transportation.paymentCompleted') }}</h2>
          <i class="fas fa-check-circle success_icon text-cyan my-2"></i>
          <p class="text-gray font-size-16 font-weight-bold mb-0">{{$t('transportation.thanks')}}</p>
        </div>
        <div class="d-flex align-items-center flex-wrap flex-lg-nowrap justify-content-center justify-content-md-start  gap_2 mr-md-5 pr-lg-5 position-relative alert-info-container">
          <h4 class="text-gray ml-4 alert-info-title text-center text-md-right">{{$t('transportation.downloadApp')}}</h4>
          <div class="alert-info-img-wrapper">
            <img src="@/assets/images/ibbil/transportation/qrcode.png" alt="" class="img-fluid alert-info-img" />
          </div>
        </div>
      </b-alert>
      <div>
        <h3 class="fees-company-main-title text-gray">{{ $t('transportation.shipmentData') }}</h3>
        <b-row>
          <b-col lg="12">
            <div class="fees-company-item">
              <div class="d-flex justify-content-between align-items-start align-items-lg-end flex-lg-row flex-column">
                <div class="d-flex gap_5">
                  <div>
                    <div class="mb-4">
                      <h5 class="fees-company-item-title">{{ $t('transportation.applicant') }}</h5>
                      <h5 class="fees-company-item-response font-size-16">على حسين محمود</h5>
                    </div>
                    <div class="mb-4">
                      <h5 class="fees-company-item-title">{{$t('transportation.transportationDate')}}</h5>
                      <h5 class="fees-company-item-response font-size-16">15 / 9 / 2022 - 05:30 KSA</h5>
                    </div>
                    <div class="mb-4">
                      <h5 class="fees-company-item-title">{{$t('transportation.startLocation')}}</h5>
                      <h5 class="fees-company-item-response font-size-16">مدينة الصفا - شارع الامل مبنى 18</h5>
                    </div>
                    <div class="mb-4">
                      <h5 class="fees-company-item-title">{{$t('transportation.endLocation')}}</h5>
                      <h5 class="fees-company-item-response font-size-16">عشيرة - حى الاقصى</h5>
                    </div>
                    <div class="mb-4">
                      <h5 class="fees-company-item-title">{{$t('transportation.tripDistance')}}</h5>
                      <h5 class="fees-company-item-response font-size-16">2500 كيلومتر</h5>
                    </div>
                  </div>
                  <div class="fees-company-item-img">
                    <div class="mb-4">
                      <h5 class="fees-company-item-title">{{$t('transportation.vehicleType')}}</h5>
                    </div>
<!--                    <img :src="insuranceCompanyInfos.logo" alt="" class="img-fluid" />-->
                    <h5 class=" mt-4 fees-company-item-title">دينا مكيفة 5 متون</h5>
                    <h5 class="fees-company-item-title">مغلقة + مكيفة</h5>
                    <h5 class="fees-company-item-title">من 1 ل 5 متون</h5>
                  </div>
                </div>
                <div class="w-25 fees-company-item-footer">
                  <div>
                    <div class="mb-4">
                      <h5 class="fees-company-item-response font-size-16">{{$t('transportation.totalValueTransport')}}</h5>
                      <h3 class="font-weight-bold text-warning">365 ريال سعودي</h3>
                    </div>
                    <div class="d-flex justify-content-start gap_2">
                      <b-button variant="primary" class="px-4 py-2 iq-border-radius-5 text-center" @click="$router.push({name: 'transportation-profile'})">
                        {{ $t('endUser.userProfile') }}</b-button>
<!--                      <b-button variant="outline-warning" class="px-4 py-2 iq-border-radius-5 text-center text-warning">الإيصال</b-button>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      insuranceCompanyInfos:
        {
          company: 'one',
          logo: require('../../../../../assets/images/ibbil/elraghi.png'),
          insuredAmount: '5,000 ريال سعودى',
          certificationFees: '20 ريال سعودى',
          transportationCoverage: '300 ريال سعودى',
          tax: '45 ريال سعودى',
          total: '365 ريال سعودى'
        }
    }
  },
  components: {
  },
  methods: {
    submitFinal () {
      console.log('final')
    }
  }
}
</script>

<style  lang="scss">
.alert-info-head {
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 1px ;
    height: 70%;
    background-color: #707070;
  }
  @media(max-width: 767px) {
    &::after {
      width: 117px;
      height: 1px;
      top: auto;
      bottom: 0;
      transform: translateX(-50%) translateY(0);
      left: 50%;
    }
  }
}
.alert-info-container {
  .alert-info-title {
    color: #646464 !important;
    line-height: 34px !important;
    font-weight: 600 !important;
  }
  .alert-info-img-wrapper {
    padding: 15px;
    border-radius: 13px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #fff;
    .alert-info-img {
      max-width: 140px !important;
    }
  }
}
.fees-company-container {
  margin-bottom: 52px;
  .fees-company-main-title {
    font-size: 20px !important;
    margin-bottom: 32px !important;
    font-weight: bold !important;
  }
  .fees-company-item {
    box-shadow: 0px 3px 20px #0000000D;
    padding: 45px 50px 20px;
    background-color: #fff;
    border-radius: 20px;
    border: 3px solid transparent;
    cursor: pointer;
    transition: .3s;
    &.selected {
      border-color:#6EBF97;
    }
    .fees-company-item-footer {
      @media (max-width: 991px) {
        width: 100% !important;
      }
    }
    .fees-company-item-body {
      padding-bottom: 27px;
      margin-bottom: 16px;
      border-bottom: 1px solid #DEDEDE;
    }
    .fees-company-item-info-box {
      margin-bottom: 22px;
      &.last {
        padding-bottom: 22px;
        border-bottom: 1px solid #DEDEDE;
      }
    }
    .fees-company-item-title {
      color: #B1B1B1;
      font-size: 16px;
      margin-bottom: 4px;
    }
    .fees-company-item-response {
      color: #9B9B9B;
      font-size: 20px;
      font-weight: bold;
    }
    .fees-company-item-img, .fees-company-item-info-total {
      .fees-company-item-title {
        font-size: 20px;
      }
      .fees-company-item-response {
        font-size: 20px;
      }
    }
    .fees-company-item-info-total {
      .fees-company-item-response {
        color: #D39D45;
      }
    }
    .fees-company-item-img {
      img {
        max-width: 150px;
      }
    }
    .fees-company-item-note {
      color: #646464;
      font-size: 16px;
      max-width: 290px;
    }
    .privacy-btn {
      font-size: 16px;
      color: #646464;
      padding-bottom: 3px;
      border-bottom: 1px solid #646464;
      font-weight: bold;
      margin-inline-end: 18px;
    }
    .choose {
      height: 50px;
      border: 1px solid #2B584280;
      border-radius: 10px;
      color: #2B5842;
      font-size: 20px;
      width: 111px;
      background-color: transparent;
    }
  }
}
.bg-card-info {
  background: #2B58420D !important;
}
.flex-1 {
  flex: 1;
}
.bg-card-gray {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  background: #2B58420D !important;
  .card-body {
    padding-bottom: 0 !important;
  }
}
.border-header-bottom {
  border-bottom: 1px solid rgba(148, 147, 147, 0.61);
}
.bg-primary-light {
  background: #2B5842BF !important;
}
.success_icon{
  font-size: 60px;
}
.text-decoration-underline{
  text-decoration: underline !important;
}
</style>
